@import url("npm:normalize.css");
@import url("./prism-theme.css");
@import url("./fonts.css");

* {
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Serif", serif;
  letter-spacing: 0.01rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans", sans-serif;
}

code {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}
