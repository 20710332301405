/* ibm-plex-serif-100 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-100.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-100.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-100italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 100;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-100italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-100italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-200 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 200;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-200.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-200.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-200italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 200;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-200italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-200italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-300italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-300italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-300italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-300 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-regular - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-500 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-500.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-500italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 500;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-500italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-500italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-600 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-600.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-600italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-600italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-600italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-700 - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-serif-700italic - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 700;
  src:
    local(""),
    url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-700italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("IBMPlexSerif/ibm-plex-serif-v14-latin-ext_latin-700italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
