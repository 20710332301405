/* noto-sans-regular - latin-ext_latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("NotoSans/noto-sans-v25-latin-ext_latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("NotoSans/noto-sans-v25-latin-ext_latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin-ext_latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("NotoSans/noto-sans-v25-latin-ext_latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("NotoSans/noto-sans-v25-latin-ext_latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-italic - latin-ext_latin */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src:
    local(""),
    url("NotoSans/noto-sans-v25-latin-ext_latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("NotoSans/noto-sans-v25-latin-ext_latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700italic - latin-ext_latin */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  src:
    local(""),
    url("NotoSans/noto-sans-v25-latin-ext_latin-700italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("NotoSans/noto-sans-v25-latin-ext_latin-700italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
